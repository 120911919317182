import { SET_SETTING, SET_ABUSE, SET_PAUSE_REASONS } from "../types";
import { api } from "../../../api/api";
import { QueueApi } from "../../../api/queueApi";
import { NotificationApi } from "../../../api/notificationApi";
import toast from 'react-hot-toast';
import { store } from "../../storeConfig/store";
import { GlobalVariable } from "../../../util/index";
import { history } from "../../../history";
import moment from "moment";
import { setLoaderAction } from "../loader/index";
import {
  invalidPasswordCheck,
  createEncryptString,
  genaretPublicKey,
} from "../../actions/auth/index";
import { getTranslation } from "../../../i18n/i18n";
const api_status = GlobalVariable.apiResponseStatus;

/**
 @name  addAndUpdateSetting
 @file setting.js
 @description This method is used for add / update setting tab 
 */
export const addAndUpdateSetting = (setting) => {
  return async (dispatch, store) => {
    let {
      signageNote,
      signageSliderTime,
      signageImage,
      timeZone,
      // branchTime,
      sigangeDeleteImage,
      digitalSigangeUpdate,
      // progressBarCount,
      passwordPolicy,
      // activeTab,
      isSignageThirdPartyEnable,
      // signageThirdPartyAPI,
      signageThirdPartyInterval,
      // signageAPIMethod,
      branchNearByKm,
      preventCustomerBookingOption,
      conciergeDisclaimer,
      isPauseReason
    } = setting;
    dispatch(setLoaderAction(true));
    let settingData = {
      merchantID: store().auth.login.user.merchantID,
      signImageShowTime: signageSliderTime,
      isSignageThirdPartyEnable,
      // signageThirdPartyAPI,
      signageThirdPartyInterval,
      // signageAPIMethod: signageAPIMethod.value,
      signageAnnouncements: signageNote,
      timeZone: timeZone,
      digitalSigangeUpdate,
      changePasswordDays: Number(passwordPolicy.changePasswordDays),
      minPasswordLength: Number(passwordPolicy.minPasswordLength),
      maxPasswordLength: Number(passwordPolicy.maxPasswordLength),
      minSpecialChar: Number(passwordPolicy.minSpecialChar),
      minNumbers: Number(passwordPolicy.minNumbers),
      minLowerCase: Number(passwordPolicy.minLowerCase),
      minUpperCase: Number(passwordPolicy.minUpperCase),
      failAttemps: Number(passwordPolicy.failAttemps),
      failAttempsWithHour: Number(passwordPolicy.failAttempsWithHour),
      noOfOldPasswords: Number(passwordPolicy.noOfOldPasswords),
      branchNearByKm: Number(branchNearByKm),
      preventCustomerBookingOption,
      conciergeDisclaimer: conciergeDisclaimer?.conciergeDisclaimer?.trim(),
      conciergeDisclaimerArb: conciergeDisclaimer?.conciergeDisclaimerArb?.trim(),
      isPauseReason: isPauseReason
    };

    let settignGet = await api("merchant/updateSetting", settingData, "put");

    let images = [];
    // let deleteImage = [];
    // let form = new FormData();
    // form.append("merchantID", store().auth.login.user.merchantID);

    if (signageImage.length > 0) {
      for (let i = 0; i < signageImage.length; i++) {
        if (signageImage[i].status === "a" || signageImage[i].status === "u") {
          // form.append("images", signageImage[i].file);
          images.push({
            image: signageImage[i].image,
            status: signageImage[i].status,
            id: signageImage[i].id,
            time: signageImage[i].time,
            size: signageImage[i].size,
          });
        }
      }
    }

    // for (let i = 0; i < sigangeDeleteImage.length; i++) {
    //   deleteImage.push(sigangeDeleteImage[i].id);
    // }

    // if (deleteImage.length > 0) {
    //   form.append("deleteImages", deleteImage.join());
    // } else {
    //   form.append("deleteImages", "");
    // }

    let updateimages = {
      merchantID: store().auth.login.user.merchantID,
      images: [...images, ...sigangeDeleteImage],
    };

    // form.append("images", images)
    let fileSize = 0;
    for (let i = 0; i < updateimages.images.length; i++) {
      if (fileSize + updateimages.images[i].size) {
        fileSize = fileSize + updateimages.images[i].size;
      }
    }
    if (50000000 < fileSize) {
      dispatch(setLoaderAction(false));
      return toast.error(getTranslation("GLOBLE_MESSAGE.fileSizeNotBiggerThen50Mb"));
    }
    // const config = {
    //   onUploadProgress: function (progressEvent) {
    //     var percentCompleted = Math.round(
    //       (progressEvent.loaded * 100) / progressEvent.total
    //     );
    //     progressBarCount(percentCompleted);
    //   },
    // };

    // let settignImage = await api(
    //   "merchant/updateSignageImage",
    //   updateimages,
    //   "putImage"
    //   // config
    // );

    // let settingTime = [];
    // for (let i = 0; i < branchTime.length; i++) {
    //   if (branchTime[i].status === "a" || branchTime[i].status === "u") {
    //     let updateData = {
    //       ...branchTime[i],
    //       startTime: moment(branchTime[i].start).format("HH:mm"),
    //       endTime: moment(branchTime[i].end).format("HH:mm"),
    //     };
    //     settingTime.push(updateData);
    //   }
    // }

    // if (settingTime.length > 0) {
    //   let Hoursdata = {
    //     merchantID: store().auth.login.user.merchantID,
    //     hours: settingTime,
    //   };
    //   await api("merchant/updateTime", Hoursdata, "put");
    // }

    if (settignGet.status === api_status.success) {

      if (setting.activeTab === "1") {
        toast.success(getTranslation("SETTING.timeLocationUpdateSuccess"));
      }
      // if (setting.activeTab === "2") {
      //   toast.success("Business hours tab has been successfully updated");
      // }
      if (setting.activeTab === "3") {
        toast.success(getTranslation("SETTING.digitalSignageUpdateSuccess"));
        history.go(0);
      }
      if (setting.activeTab === "6") {
        toast.success(getTranslation("SETTING.passwordPolicyUpdateSuccess"));
      }
      if (setting.activeTab === "10") {
        toast.success(getTranslation("SETTING.smartTabUpdatedSuccessFully"));
      }
      // toastr.success(getTranslation('SETTING.update'));

      if (setting.activeTab === "11") {
        toast.success(getTranslation("SETTING.update"));
      }
      // toast.success(getTranslation('SETTING.update'));

      await dispatch(getSetting());
      dispatch(setLoaderAction(false));
    } else {
      if (settignGet.status === api_status.unAuthorized) {
        toast.error(settignGet.message);
        dispatch(setLoaderAction(false));
      } else {
        toast.error(settignGet.message.message);
        dispatch(setLoaderAction(false));
      }
    }
  };
};

/**
 @name  addAndUpdateAbuse
 @file setting.js
 @description This method is used for add / update abuse  
 */
export const addAndUpdateAbuse = (data) => {
  return async (dispatch, store) => {
    let { allowTickets, status, id, recallForSmartAgent, activeTab } = data;
    if (!allowTickets) {
      toast.error(getTranslation("SETTING.nonEmptyTicketNumber"));
      return true;
    }
    let abuseData = {};
    if (status === "a") {
      abuseData = {
        status,
        allowTickets,
        merchantId: store().auth.login.user.merchantID,
        recallForSmartAgent
      };
    } else {
      abuseData = {
        id: id,
        status,
        allowTickets,
        merchantId: store().auth.login.user.merchantID,
        recallForSmartAgent
      };
    }

    let queueSetting = await QueueApi(`setting`, abuseData, "post");

    if (queueSetting.status === api_status.success) {
      if (activeTab === "10") {
        toast.success(getTranslation("SETTING.smartTabUpdatedSuccessFully"));
      }else{
        toast.success(getTranslation("SETTING.abuseUpdateSuccess"));
      }

      //   await dispatch(getAbuseData());
    } else {
      toast.error(queueSetting.message.message);
    }
  };
};

/**
 @name  setBannerImage1
 @file setting.js
 @description This method is used save banner imgaes 
 */
export const setBannerImage1 = (data) => {
  return async (dispatch, store) => {
    data.merchantID = store().auth.login.user.merchantID
    dispatch(setLoaderAction(true));
    let settignImage = await api("banner/event", data, "post");
    if (settignImage.status === api_status.success) {
      toast.success(getTranslation("BANNER.banerTabUpdate"));
      dispatch(setLoaderAction(false));
      return true;
    } else {
      toast.error(settignImage.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

/**
 @name  getBannerImages
 @file setting.js
 @description This method is used to get banner imgaes 
 */
export const getBannerImages = async ({ page, limit }) => {
  let bannersGet = await api(
    `banner/event?page=${page}&limit=${limit}`,
    {},
    "get"
  );
  if (bannersGet.status === api_status.success) {
    return {
      data: bannersGet.data.data.images,
      count: bannersGet.data.count,
    };
  } else {
    return {
      data: [],
      count: 0,
    };
  }
};

/**
 @name  getAbuseData
 @file setting.js
 @description This method is used for get abuse  data
 */
export const getAbuseData = (merchantId) => {
  return async (dispatch, store) => {
    dispatch(setLoaderAction(true));;

    let abuseGet = await QueueApi(
      `setting?merchantId=${store().auth.login.user.merchantID}`,
      {},
      "get"
    );
    if (abuseGet.status === api_status.success) {
      dispatch({
        type: SET_ABUSE,
        payload: abuseGet.data.data,
      });
      dispatch(setLoaderAction(false));
    } else {
      // toast.error(settignGet.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

/**
 @name  addAndUpdateHoliday
 @file setting.js
 @description This method is used for add / update holiday
 */ 
export const addAndUpdateHoliday = (data) => {
  return async (dispatch, store) => {
    let { holidays, deleteHolidays } = data;

    let updateHoliday = [];
    for (let i = 0; i < holidays.length; i++) {
      if (!holidays[i].name) {
        toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"));
        return true;
      }

      if (!holidays[i].date) {
        toast.error(getTranslation("GLOBLE_MESSAGE.dateRequired"));
        return true;
      }
      if (holidays[i].status !== "o") {
        updateHoliday.push({
          name: holidays[i].name,
          date: moment(holidays[i].date).startOf("day").valueOf(),
          status: holidays[i].status,
          id: holidays[i].id,
        });
      }
    }

    let reqdata = [...deleteHolidays, ...updateHoliday];
    dispatch(setLoaderAction(true));

    let settingData = {
      merchantID: store().auth.login.user.merchantID,
      holiday: reqdata,
    };

    let settignGet = await api("merchant/updateHoliday", settingData, "put");

    if (settignGet.status === api_status.success) {
      toast.success(getTranslation("HOLIDAY.update"));
      await dispatch(getSetting());
      dispatch(setLoaderAction(false));
    } else {
      if (settignGet.status === api_status.unAuthorized) {
        toast.error(settignGet.message);
        dispatch(setLoaderAction(false));
      } else {
        toast.error(settignGet.message.message);
        dispatch(setLoaderAction(false));
      }
    }
  };
};

/**
 @name  getSetting
 @file setting.js
 @description This method is used for get setting
 */
export const getSetting = (merchantId) => {
  return async (dispatch, store) => {
    // dispatch(setLoaderAction(true));

    let settingData = {
      merchantID: store().auth.login.user.merchantID,
    };

    let settignGet = await api(
      `merchant/setting?merchantID=${settingData.merchantID}&isDeletedPauseReason=true`,
      {},
      "get"
    );

    if (settignGet.status === api_status.success) {
      await dispatch(getAbuseData(merchantId));
      dispatch({
        type: SET_SETTING,
        payload: settignGet.data.data,
      });
      dispatch({
        type: SET_PAUSE_REASONS,
        payload: settignGet.data.data.PauseReasons
      })
      return false
      // dispatch(setLoaderAction(false));
    } else {
      // toast.error(settignGet.message.message);
      // dispatch(setLoaderAction(false));
      return false
    }
  };
};

/**
 @name  getPauseReasonOptions
 @file setting.js
 @description This method is used for get Pause Reasons
 */
 export const getPauseReasonOptions = (merchantId) => {
  return async (dispatch, store) => {
    // dispatch(setLoaderAction(true));

    let settingData = {
      merchantID: store().auth.login.user.merchantID,
    };

    let settignGet = await api(
      `merchant/setting?merchantID=${settingData.merchantID}&isDeletedPauseReason=true`,
      {},
      "get"
    );

    if (settignGet.status === api_status.success) {
      dispatch({
        type: SET_PAUSE_REASONS,
        payload: settignGet.data.data.PauseReasons
      })
      return settignGet.data.data.PauseReasons
    }
  };
}

export const getIsPauseReason = () => {
  return async (dispatch, store) => {
    let settingData = {
      merchantID: store().auth.login.user.merchantID,
    };

    let settignGet = await api(
      `merchant/setting?merchantID=${settingData.merchantID}`,
      {},
      "get"
    );

    if (settignGet.status === api_status.success) {
      return settignGet.data.data
    }
  }
}

/**
 @name  getBranchListAction
 @file setting.js
 @description This method is used for get banner data
 */
export const getBranchListAction = async (id) => {
  const branchData = await api(`branchList/${id}`, {}, "get");
  if (branchData.status === api_status.success) {
    if (branchData.data.data && branchData.data.data.length > 0) {
      let branchList = [{ value: "all", label: "All" }];
      for (let i = 0; i < branchData.data.data.length; i++) {
        branchList.push({
          value: branchData.data.data[i].id,
          label: branchData.data.data[i].name,
        });
      }
      return branchList;
    } else {
      return [];
    }
  }
};

export const deleteBannerImages = async (id) => {
  let bannersDelete = await api(`delete/banner/event?id=${id}`, {}, "delete");
  if (bannersDelete.status === api_status.success) {
    return true;
  } else {
    toast.error(bannersDelete.message.message);
    return false;
  }
};

// get agent list by selected branch
export const getAgentListAction = async (branchId, merchantID) => {
  let sendToData = {
    role: "agent",
    isActive: true,
    merchantID
  }
  if (branchId) {
    sendToData['branch'] = branchId
  }
  const agentListData = await api(`getByMerchant`, sendToData, "post");
  if (agentListData.status === api_status.success) {
    if (agentListData.data.data && agentListData.data.data.user.length > 0) {
      let agentListDataList = [{ value: "all", label: "All" }];
      for (let i = 0; i < agentListData.data.data.user.length; i++) {
        agentListDataList.push({
          value: agentListData.data.data.user[i].id,
          label: agentListData.data.data.user[i].name,
        });
      }
      return agentListDataList;
    }
  }
};

export const notifyAgentAction = async (data, branch) => {
  const { payload, agentId } = data;
  if (!payload.title) {
    toast.error(getTranslation("SETTING.titleRequired"));
    return true;
  }
  if (!payload.description) {
    toast.error(getTranslation("SETTING.descriptionRequired"));
    return true;
  }
  if (branch.length === 0) {
    toast.error(getTranslation("SETTING.selectBranch"));
    return true;
  }
  if (agentId.length === 0) {
    toast.error(getTranslation("SETTING.selectAgent"));
    return true;
  }
  const notifiedAgent = await NotificationApi(`agent/notify`, data, "post");

  if (notifiedAgent.status === api_status.success) {
    toast.success(getTranslation("SETTING.notificationSentSuccess"));
    return notifiedAgent;
  } else {
    toast.error(getTranslation("SETTING.notificationSendingProblem"));
  }
};
export const getAdminSettingAction = async () => {
  let queueSetting = await QueueApi(`setting?merchantId=all`, {}, "get");
  if (queueSetting.status === api_status.success) {
    return queueSetting.data.data;
  }
};

/**
 @name  addUpdateSegmentation
 @file setting.js
 @description This method is used for add / update segment
 */
export const addUpdateSegmentation = async (segment) => {
  let merchantID = store.getState().auth.login.user.merchantID;

  let { customerSegmentsData, deletedCustomerSegmentsData } = segment;
  let isSegmentationValid = customerSegmentsData.filter(
    (item) =>
      item.name === "" ||
      item.isInValidName === true ||
      item.ticketPerRound === "" ||
      item.isInValidTicketPerRound ||
      item.prefix === "" ||
      item.isInValidPrefix === true
  );
  if (customerSegmentsData.length === 0) {
    toast.error(getTranslation("SETTING.customerSegmentRequired"));
    return true;
  }
  if (isSegmentationValid.length > 0) {
    toast.error(getTranslation("SETTING.allFieldsCustomerSegmentRequired"));
    return true;
  }
  // check edited item , status o is for not updated segment
  let customerSegmentsAddUpdateDeleteData = customerSegmentsData.filter(
    (item) => item.status !== "o"
  );
  // add merchant id in segment object
  let segmentsData = customerSegmentsAddUpdateDeleteData.map((item) => {
    return { ...item, merchantID: merchantID };
  });
  // deleted segment
  let deletedSegment = deletedCustomerSegmentsData
    .filter((item) => item.status === "u" || item.status === "o")
    .map((item) => {
      return { ...item, status: "d" };
    });
  if (deletedSegment.length > 0) {
    deletedSegment.map((item) => segmentsData.push(item));
  }
  let segmentationData = await api("update/segmentation", segmentsData, "post");
  if (segmentationData.status === api_status.success) {
    toast.success(getTranslation("SETTING.segmentationUpdateSuccess"));
    return segmentationData.status;
  }
};

/**
 @name  getSegmentationAction
 @file setting.js
 @description This method is used for get segment data
 */
export const getSegmentationAction = async (branchId = "") => {
  let merchantId = store.getState().auth.login.user.merchantID;
  let segmentationData = await api(
    `get/segmentation?merchantId=${merchantId}${branchId ? "&branchId=" + branchId : ""
    }`,
    {},
    "get"
  );
  if (segmentationData.status === api_status.success) {
    let customerSegmentsData = segmentationData.data.data
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((item) => {
        return {
          default: item.default,
          isError: "",
          isInValidName: item.isInValidName,
          isInValidPrefix: item.isInValidPrefix,
          isInValidTicketPerRound: item.isInValidTicketPerRound,
          isValidName: item.isValidName,
          isValidPrefix: item.isValidPrefix,
          isValidTicketPerRound: item.isValidTicketPerRound,
          merchantID: item.merchantID,
          servicesID: item?.servicesID || "",
          name: item.name,
          prefix: item.prefix,
          sortOrder: item.sortOrder,
          status: "o",
          ticketPerRound: item.ticketPerRound,
          id: item.id,
        };
      });
    return customerSegmentsData;
  }
};

/**
 @name  ExposeAPIAction
 @file setting.js
 @description This method is used for expose api data
 */
export const ExposeAPIAction = async (state) => {
  if (!state.username) {
    toast.error(getTranslation("GLOBLE_MESSAGE.userNameRequired"));
    return true;
  }
  const isPassword = await invalidPasswordCheck(state.password);
  if (isPassword) {
    return true;
  }
  await store.dispatch(genaretPublicKey());
  let merchantId = store.getState().auth.login.user.merchantID;
  let encrytPassword = await createEncryptString(state.password);

  let exposeAPIData = {
    username: state.username,
    merchantID: merchantId,
    password: encrytPassword.encryt,
    keyId: store.getState()?.auth?.login?.publickKey?.id,
    key: encrytPassword.frontEndKey,
    id: state.id,
  };
  let data = await api("third-party/update", exposeAPIData, "post");
  if (data.status === api_status.success) {
    return data.status;
  }
};

/**
 @name  getExposeAPIAction
 @file setting.js
 @description This method is used for get expose api data
 */
export const getExposeAPIAction = async () => {
  let merchantID = store.getState().auth.login.user.merchantID;
  let data = await api(`third-party/get?merchantID=${merchantID}`, {}, "get");
  if (data.status === api_status.success) {
    return data.data.data.token;
  }
};

/**
 @name  copyTokenLog
 @file setting.js
 @description This method is used for get token for expose api
 */
export const copyTokenLog = async () => {
  let merchantID = store.getState().auth.login.user.merchantID;
  let data = {
    merchantId: merchantID,
  };
  await api("merchant/token-copy", data, "post");
};

export const checkHolidayAssignAction = async (id) => {
  try {
    const res = await api(`merchant/checkHoliday?merchantHolidayId=${id}`, {}, "get");
    if (res.status === api_status.success) {
      return true;
    } else {
      return false
    }
  } catch {
    return false
  }
}

/**
 @name  addPauseReasons
 @file setting.js
 @description This method is used for add / update PauseReasons
 */
 export const addandUpdatePauseReasons = (data) => {
  return async (dispatch, store) => {
    let { updatereason, deletePauseReasons } = data;
    if (deletePauseReasons?.length > 0) {
      deletePauseReasons = deletePauseReasons.filter(item => !item.isDeleted);
    }

    let reqdata = [...deletePauseReasons, ...updatereason];
    dispatch(setLoaderAction(true));

    let datatoSend = {
      reason : reqdata
    };

    let settignGet = await api("merchant/update-reason", datatoSend , "post");

    if (settignGet.status === api_status.success) {
      toast.success(getTranslation("SETTING.updateSuccess"));
      await dispatch(getSetting());
      dispatch(setLoaderAction(false));
    } else {
      if (settignGet.status === api_status.unAuthorized) {
        toast.error(settignGet.message);
        dispatch(setLoaderAction(false));
      } else {
        toast.error(settignGet.message.message);
        dispatch(setLoaderAction(false));
      }
    }
  };
};