import i18n, { getTranslation } from "./i18n/i18n";

// export const SETTING_BREADCRUM = "Settings";
// export const SETTING_STATIC_TAB_TIMELOCATION = "Time and Location";
// export const SETTING_STATIC_TAB_BUSINESSHOUR = "Default Business Hours";
// export const SETTING_STATIC_TAB_SEGMENTATION = "Segmentation";
// export const SETTING_STATIC_TAB_DIGITALSIGNAGE = "Digital Signage";
// export const SETTING_STATIC_TAB_HOLIDAY = "Holidays";
// export const SETTING_STATIC_TAB_Abuse = "Abuse";
// export const SETTING_STATIC_Event_Banners = "Event Banners";
// export const SETTING_STATIC_TAB_PASSWORD_POLICT = "Password Policy";
// export const SETTING_STATIC_TAB_SYSTEM_APIS_CONFIG =
//   "System Logs APIs Configurations";
export const SETTING_STATIC_TAB_SMART_BRANCH = "Smart Branch";

// export const BRANCH_HISTORY_STATUS_VALUE_ALL = "all";
// export const BRANCH_HISTORY_STATUS_LABEL_ALL = "All";
// export const BRANCH_HISTORY_STATUS_VALUE_PROCESSED = "processed";
// export const BRANCH_HISTORY_STATUS_LABEL_PROCESSED = "Processed";
// export const BRANCH_HISTORY_STATUS_VALUE_NOTPROCESSED = "Not processed";
// export const BRANCH_HISTORY_STATUS_LABEL_NOtPROCESSED = "Not processed";
// export const BRANCH_HISTORY_STATUS_VALUE_NOSHOW = "no-show";
// export const BRANCH_HISTORY_STATUS_LABEL_NOSHOW = "No-Show";
// export const BRANCH_HISTORY_STATUS_VALUE_CANCELLED = "cancelled";
// export const BRANCH_HISTORY_STATUS_LABEL_CANCELLED = "Cancelled";
// export const BRANCH_HISTORY_STATUS_VALUE_COMPLETED = "completed";
// export const BRANCH_HISTORY_STATUS_LABEL_COMPLETED = "Completed";

// export const BRANCH_BREADCRUM = "Branches";
// export const TICKET_HISTORY_BREADCRUM = "Ticket History";

// export const DASHBOARD_BREADCRUM = "Dashboard";

// CSV DOWNLOAD HEADERS for Dashboard

// export const TICKET_PER_DAY_HEADER = [
//   { label: "Day Name", key: "day" },
//   { label: "Tickets per day", key: "tickets" },
// ];

// export const AGENT_VS_AVG_RATING_HEADER = [
//   { label: "Agent Name", key: "agentName" },
//   { label: "Average Rating", key: "avg" },
// ];

// export const BRANCH_VS_AVG_RATING_HEADER = [
//   { label: "Branch Name", key: "branchName" },
//   { label: "Average Rating", key: "avg" },
// ];

// export const SERVICE_VS_AVG_RATING_HEADER = [
//   { label: "Service Name", key: "serviceName" },
//   { label: "Average Rating", key: "avg" },
// ];

// export const AVG_SERVICE_VS_KPI_HEADER = [
//   { label: "Branch Name", key: "branchName" },
//   { label: "Average Service Time", key: "avgSeviceTIme" },
//   { label: "Kpi", key: "kpi" },
// ];

// export const TICKET_PER_SERVICE_HEADER = [
//   { label: "Service Name", key: "serviceName" },
//   { label: "Number of tickets", key: "count" },
// ];

// export const TICKET_PER_BRANCH_HEADER = [
//   { label: "Branch Name", key: "branchName" },
//   { label: "Number of tickets", key: "count" },
// ];

// export const TICKET_PER_AGENT_HEADER = [
//   { label: "Agent Name", key: "agentName" },
//   { label: "Number of tickets", key: "count" },
// ];

// export const WEEK_DAY_HEADER = [
//   { day: "Sunday", tickets: 0 },
//   { day: "Monday", tickets: 0 },
//   { day: "Tuesday", tickets: 0 },
//   { day: "Wednesday", tickets: 0 },
//   { day: "Thursday", tickets: 0 },
//   { day: "Friday", tickets: 0 },
//   { day: "Saturday", tickets: 0 },
// ];

// export const USER_BREADCRUM = "Users";
// export const EDIT_USER_BREADCRUM = "Edit User";
export const USER_STATUS_HEADER = () => {
  return [
     { value: "All", label: getTranslation("CATEGORY.All") },
     { value: "Active", label: getTranslation("CATEGORY.Active") },
     { value: "Inactive", label: getTranslation("CATEGORY.Inactive") },
  ];
 };
// export const USER_EXPORT_CSV_HEADER = [
//   {
//     label: "Name",
//     key: "name",
//   },
//   { label: "Email", key: "email" },
//   { label: "Branch", key: "branchName" },
//   { label: "Status", key: "isActive" },
//   { label: "Role", key: "role" },
// ];

// export const ADD_USER_BREADCRUM = "Add New user";
export const USER_STATUS = () => [
  { value: "active", label:i18n.t("CATEGORY.Active") },
  { value: "inactive", label:i18n.t("CATEGORY.Inactive") },
];

// export const SERVICE_BREADCRUM = "Service";
// export const SERVICES_BREADCRUM = "Services";
export const SERVICE_EXPORT_CSV_HEADER = () => [
  { label: getTranslation("SERVICE.Service Name"), key: "name" },
  { label: getTranslation("SERVICE.Service Type"), key: "type" },
  { label: getTranslation("SERVICE.categoryType"), key: "serviceCategoryName" },
  { label: getTranslation("SERVICE.Parent Category"), key: "parentCategoryName" },
  { label: getTranslation("SERVICE.Status"), key: "isActive" },
  { label: getTranslation("SERVICE.Serving Time"), key: "servingTime" },
];

// export const ADD_SERVICE_BREADCRUM = "Add New Service";
// export const ADD_SERVICE_STATUS = [
//   { value: "active", label:i18n.t("CATEGORY.Active")},
//   { value: "inactive", label:i18n.t("CATEGORY.Inactive") },
// ];

// export const CATEGORY_BREADCRUM = i18n.t("CATEGORY.Categories");
// export const ADD_CATEGORY_BREADCRUM = i18n.t("CATEGORY.Add Category");
// export const EDIT_CATEGORY_BREADCRUM = i18n.t("CATEGORY.Edit Category");
export const CATEGORY_EXPORT_CSV_HEADER = () => [
  {
    label: getTranslation("CATEGORY.Category Name"),
    key: "name",
  },
  {
    label: getTranslation("CATEGORY.Parent Category"),
    key: "parentCategory",
  },
  {
    label: getTranslation("CATEGORY.Category Type"),
    key: "type",
  },
  {
    label: getTranslation("CATEGORY.Status"),
    key: "isActive",
  },
];

// export const QUEUE_BREADCRUM = "Queues";
export const QUEUE_EXPORT_CSV_HEADER = () => [
  {
    label: getTranslation("QUEUE.QueueName"),
    key: "name",
  },
  {
    label: getTranslation("QUEUE.IncludedServices"),
    key: "services",
  },
  {
    label: getTranslation("QUEUE.Status"),
    key: "isActive",
  },
];

// export const ADD_QUEUE_BREADCRUM = "Add New Queue";
// export const FEEDBACK_BREADCRUM = "Feedback";

// export const FEEDBACK_CSV_EXPORT_HEADER = [
//   {
//     label: "Customer Name",
//     key: "customerName",
//   },
//   {
//     label: "Comment/Audio",
//     key: "voiceNote",
//   },
//   {
//     label: "Rating",
//     key: "rating",
//   },
//   {
//     label: "Branch",
//     key: "ticketId.branchName",
//   },
//   {
//     label: "Service",
//     key: "serviceName",
//   },
//   {
//     label: "Agent",
//     key: "agentName",
//   },
//   {
//     label: "Ticket Created At",
//     key: "createdAt",
//   },
//   {
//     label: "Ticket Served At",
//     key: "startedAt",
//   },
//   {
//     label: "Waiting Time",
//     key: "waitingTime",
//   },
//   {
//     label: "Serving Time",
//     key: "processingTime",
//   },
// ];

// export const EDIT_QUEUE_BREADCRUM = "Edit Queue";
// export const ADD_BRANCH_BREADCRUM = "Add New Branch";

export const BRANCH_TIMING_WEEK_JSON = [
  {
    day: "Sunday",
    start: new Date(),
    end: new Date(),
    isWorking: true,
  },
  {
    day: "Monday",
    start: new Date(),
    end: new Date(),
    isWorking: true,
  },
  {
    day: "Tuesday",
    start: new Date(),
    end: new Date(),
    isWorking: true,
  },
  {
    day: "Wednesday",
    start: new Date(),
    end: new Date(),
    isWorking: true,
  },
  {
    day: "Thursday",
    start: new Date(),
    end: new Date(),
    isWorking: true,
  },
  {
    day: "Friday",
    start: new Date(),
    end: new Date(),
    isWorking: false,
  },
  {
    day: "Saturday",
    start: new Date(),
    end: new Date(),
    isWorking: false,
  },
];

// export const EDIT_SERVICE_BREADCRUM = "Edit Service";
// export const EDIT_BRANCH_BREADCRUM = "Edit Branch";

// export const LOG_BREADCRUM = "Logs";
// export const LOG_HISTORY_BREADCRUM = "Log History";

// export const WORK_PROFILE_BREADCRUM = "Work Profile";

export const LOG_EXPORT_CSV_HEADER = () =>[
  { label: getTranslation("LOGS.action"), key: "action" },
  { label: getTranslation("LOGS.targetedItem"), key: "targetedItem" },
  { label: getTranslation("LOGS.editor"), key: "loginName" },
  { label: getTranslation("LOGS.role"), key: "loginRole" },
  { label: 'Ip Address', key: "loginIP" },
  { label: getTranslation("LOGS.actionDateTime"), key: "createdAt" },
];

// export const LOG_ROLE_JSON = [
//   { value: "all", label: "All" },
//   { value: "merchant", label: "Merchant" },
// ];

// export const PROFILE_BREADCRUM = "Profile";
// export const EDIT_PROFILE_BREADCRUM = "Edit profile";
// export const CHANGE_PASSWORD_BREADCRUM = "Change Password";

export const DASHBOARD_BRANCH_VALUE = "all";
export const DASHBOARD_SERVICE_VALUE = "all";
export const DASHBOARD_AGENT_VALUE = "all";

// export const FEEDBACK_STATUS_VALUE_ALL = "all";
// export const FEEDBACK_STATUS_LABEL_ALL = "All";

// service error msg
// export const SERVICE_PREFIX_TAKEN = "Oh no! That prefix is already taken!";
// export const SERVIVE_PREFIX_CHARACTER="Special characters are not allowed!"
// export const SERVICE_PREFIX_MAX_CHAR =
//   "Oh no! Your should contain max 2 characters!";

// export const SERVICE_PREFIX_REQUIRE = "Prefix is required!";
// export const APPOINTMENT_PREFIX_REQUIRE = "Appointment prefix is required!";

// export const CUSTOMER_SEGMENT_UNIQUE = "Customer Segment name must  be unique!";
// export const CUSTOMER_SEGMENT_IS_REQUIRE = "Customer Segment name is required!";
// export const TICKET_PER_ROUND_IS_REQUIRE = "Ticket per Round is required!";
// export const SEGMENT_PREFIX_TAKEN = "Oh no! That prefix is already taken!";
// export const PREFIX_MAX_CHAR = "Oh no! You should contain max 1 character!";
// export const PREFIX_REQUIRE = "Prefix is required!";

export const INVALID_VIDEO_FILE = "Invalid video. Please select a video file.";
// export const FILESIZE_NOT_BIGGER_THAN_50MB =
//   "File size is not be a bigger than 50 mb";
// export const API_CALLING_EVERY_SECONDS_REQUIRE =
//   "Api calling every seconds field is required!";
// export const API_CALLING_EVERY_SECONDS_MIN_VALUE =
//   "Api calling every seconds minimum value is 10 seconds";
// export const CHANGE_PASSWORD_X_DAYS_GREATER_THAN_ZERO =
//   "Change password every X days must be greater than 0 .";
// export const MIN_PASSWORD_LENGTH = "Minimum password length is 8 .";
// export const MAX_PASSWORD_LENGTH = "Maximum password length is 16 .";
// export const MAX_GREATER_THAN_MIN_PASSWORD =
//   "Maximum password length is must be greater than minimum password length field .";
// export const MIN_SPECIAL_CHAR =
//   "Minimum Number of Special Character must be greater than 0 .";
// export const MIN_NUM_DIGIT = "Minimum Number of Digit must be greater than 0 .";
// export const MIN_NUM_LOWERCASE =
//   "Minimum Number of Lowercase Character must be greater than 0 .";
// export const MIN_NUM_UPPERCASE =
//   "Minimum Number of Uppercase Character must be greater than 0 .";
// export const NUM_ATTEMPTS_BEFORE_BLOCKING =
//   "Number of attempts before blocking the email must be greater than 0 .";
// export const DURATION_OF_BAN =
//   "Duration of the ban if user exceeds the number of attempts X Hours must be greater than 0 .";
// export const SMALLER_THAN_MAX_PASSWORD_LENGTH =
//   "Special Character , Numbers of digit, Lowercase and Uppercase lenght must be smaller than maximum password length .";
// export const PREVENT_LAST_PREV_PASSWORD =
//   "Prevent the last [X] previous passwords to be reused again field is required!.";
// export const PREVENT_LAST_PREV_PASSWORD_NUM =
//   "Prevent the last [X] previous passwords to be reused again field must be between 0 to 10 .";
// export const HOUR_VALIDATION =
//   "Enter valid value of field Duration of the ban if user exceeds the number of attempts X Hours";
export const SMART_BRANCH_TIME_VALIDATION =
  "Time to call next ticket when no show must be greater than 0 .";
// export const IS_VALID_URL = "Please add valid API address";
// export const DISTANCE_TO_ALLOW = "Distance to allow must be greater than 0!";
// regex
export const EMAIL_VALIDATION =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_VALIDATION =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,30}$/;
export const urlRegex =
  /^https?:\/\/[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{2,13}\b([-a-z0-9()@:%_\+.~#?&//=]*)$/i;
export const URLRegexMAP =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

// /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;

export const exceptThisSymbols = ["e", "E", "+", "-", "."];


// export const WORKPROFILE_BREADCRUM = "Work profile";
// export const ADD_WORKPROFILE_BREADCRUM = "Add new shift";
// export const EDIT_WORKPROFILE_BREADCRUM = "Edit shift";
// export const FILE_NOT_SUPPORTED_OTHERTHAN_SVG_PNG_JPG =
//   "File not supported other then png ,jpg";

export const WORK_PROFILE_EXPORT_CSV_HEADER = () => [
  { label: getTranslation("WORKPROFILE.workProfileName"), key: "name" },
  { label: getTranslation("WORKPROFILE.gracePeriodMinutes"), key: "graceTime" },
  { label: getTranslation("WORKPROFILE.sunday"), key: "Sunday" },
  { label: getTranslation("WORKPROFILE.monday"), key: "Monday" },
  { label: getTranslation("WORKPROFILE.tuesday"), key: "Tuesday" },
  { label: getTranslation("WORKPROFILE.wednesday"), key: "Wednesday" },
  { label: getTranslation("WORKPROFILE.thursday"), key: "Thursday" },
  { label: getTranslation("WORKPROFILE.friday"), key: "Friday" },
  { label: getTranslation("WORKPROFILE.saturday"), key: "Saturday" },
];

let startTime = new Date();
startTime.setHours(8);
startTime.setMinutes(30);
startTime.setSeconds(0);
startTime.setMilliseconds(0);
let endTime = new Date();
endTime.setHours(18);
endTime.setMinutes(0);
endTime.setSeconds(0);
endTime.setMilliseconds(0);

export const DEFUALT_WORK_HOURS = [
  {
    day: "Sunday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: true,
  },
  {
    day: "Monday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: true,
  },
  {
    day: "Tuesday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: true,
  },
  {
    day: "Wednesday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: true,
  },
  {
    day: "Thursday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: true,
  },
  {
    day: "Friday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Saturday",
    shifts: [
      {
        startTime: startTime,
        endTime: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
];

export const DEFUALT_SERVICE_WORK_HOURS = [
  {
    day: "Sunday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Monday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Tuesday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Wednesday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Thursday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Friday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
  {
    day: "Saturday",
    shifts: [
      {
        start: startTime,
        end: endTime,
        startTimeError: false,
        endTimeError: false,
      },
    ],
    isWorking: false,
  },
];

export const EVENT_BREADCRUM = "Events";
export const ADD_EVENT_BREADCRUM = "Add Event";
export const EDIT_EVENT_BREADCRUM = "Edit Event";

export const EVENT_PARTICIPANT_EXPORT_CSV_HEADER = [
  { label: "Participant ID", key: "id" },
  { label: "Status", key: "status" },
];
export const EVENT__EXPORT_CSV_HEADER = [
  { label: "Event Name", key: "name" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Total Schedules", key: "totalschedules" },
  { label: "Total Services", key: "totalservices" },
  { label: "Participants", key: "totalparticipates" },
  { label: "Status", key: "status" },
];

// banner image
// export const ONE_IMAGE_MUST_SELECTED = "At least 1 image must upload";
// export const ONE_EVENT_MUST_SELECTED = "At least 1 event must selected";

export const DEFUALT_WORK_HOURS_SERVICES = [
  {
    day: i18n.t("GLOBLE_MESSAGE.Sunday"),
    start: startTime,
    end: endTime,
    isWorking: true,
  },
  {
    day: i18n.t("GLOBLE_MESSAGE.Monday"),
    start: startTime,
    end: endTime,
    isWorking: true,
  },
  {
    day:i18n.t("GLOBLE_MESSAGE.Tuesday"),
    start: startTime,
    end: endTime,
    isWorking: true,
  },
  {
    day: i18n.t("GLOBLE_MESSAGE.Wednesday"),
    start: startTime,
    end: endTime,
    isWorking: true,
  },
  {
    day: i18n.t("GLOBLE_MESSAGE.Thursday"),
    start: startTime,
    end: endTime,
    isWorking: true,
  },
  {
    day: i18n.t("GLOBLE_MESSAGE.Friday"),
    start: startTime,
    end: endTime,
    isWorking: false,
  },
  {
    day: i18n.t("GLOBLE_MESSAGE.Saturday"),
    start: startTime,
    end: endTime,
    isWorking: false,
  },
];

export const SEGAMENT_OPTION = () => [
  { value: "customer", label: i18n.t("GLOBLE_MESSAGE.CustomerSegmentation")},
  { value: "service", label: i18n.t("GLOBLE_MESSAGE.ServiceSegmentation")},
  { value: "", label: i18n.t("GLOBLE_MESSAGE.NotAllowed")},
];

export const noShowCol = () => [
  { value: true, label: i18n.t("GLOBLE_MESSAGE.Enable")},
  { value: false, label: i18n.t("GLOBLE_MESSAGE.Disable")},
];

export const branchTicketStatus = () => [
  { value: "all", label: i18n.t("GLOBLE_MESSAGE.All") },
  // { value: "processed", label: "Processed" },
  { value: "Not processed", label: i18n.t("DASHBOARD.Not Processed") },
  { value: "no-show", label: i18n.t("DASHBOARD.No Show") },
  { value: "cancelled", label: i18n.t("REPORT.Cancelled") },
  { value: "completed", label: i18n.t("APPOINTMENTDASHBOARD.Completed") },
];

export const branchTicketType = [
  { value: "all", label: i18n.t("GLOBLE_MESSAGE.All") },
  { value: "instant", label: "Queue (Instant)" },
  { value: "appointment", label: "Appointment" },
];

export const branchTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Desk Number", key: "counter" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date", key: "createdAt" },
  { label: "Calling Time", key: "servedAt" },
  { label: "Completed Date", key: "completedAt" },
  { label: "Status", key: "status" },
];
export const OnGoingbranchTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Desk Number", key: "counter" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date", key: "createdAt" },
  { label: "Calling Time", key: "servedAt" },
  { label: "Status", key: "status" },
];
export const appointmentTicketHistoryHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Desk Number", key: "counter" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Appointment date ", key: "appointmentDate" },
  { label: "Appointment time", key: "appointmentTime" },
  { label: "Appointment calling time", key: "startedAt" },
  { label: "Appointment completed time", key: "completedAt" },
  { label: "Status", key: "status" },
];
export const appointmentOnGoingTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Desk Number", key: "counter" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Appointment date ", key: "appointmentDate" },
  { label: "Appointment time", key: "appointmentTime" },
  { label: "Appointment calling time", key: "startedAt" },
  { label: "Status", key: "status" },
];
export const eserviceHistoryTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Agent name", key: "processedByUserName" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Assigned date and time", key: "startedAt" },
  { label: "Completed date and time", key: "completedAt" },
  { label: "Status", key: "status" },
];
export const eserviceOnGoingTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Agent name", key: "processedByUserName" },
  { label: "Service", key: "serviceName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  // { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Assigned date and time", key: "startedAt" },
  { label: "Status", key: "status" },
];

export const donationHistoryTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Agent name", key: "processedByUserName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Assigned date and time", key: "startedAt" },
  { label: "Completed date and time", key: "completedAt" },
  { label: "Status", key: "status" },
];

export const donationOnGoingTicketHeaders = [
  { label: "Ticket", key: "number" },
  { label: "Agent name", key: "processedByUserName" },
  { label: "Customer", key: "customerName" },
  { label: "Customer email", key: "customerEmail" },
  { label: "Customer Mobile", key: "phone" },
  { label: "Booking Method", key: "bookingMethod" },
  { label: "Booking Date and time", key: "createdAt" },
  { label: "Assigned date and time", key: "startedAt" },
  { label: "Status", key: "status" },
];
export const categoryType = [
  { value: "queue", label: i18n.t("CATEGORY.Queue") },
  { value: "eservice", label: i18n.t("FORMS.e-Service") },
  { value: "interactive", label: "Interactive(Limited seat)" },
  { value: "informative", label: "Informative" },
];

export const serviceType = [
  { value: "queue", label: i18n.t("CATEGORY.Queue") },
  { value: "eservice", label: i18n.t("FORMS.e-Service") },
  { value: "interactive", label: "Interactive(Limited seat)" },
  { value: "informative", label: "Informative" },
  { value: "form", label: "Form Service" },
  { value: "appointment", label: i18n.t("BRANCH.Appointment")},
  { value: "donation", label: i18n.t("SERVICE.Donation")},
];

export const TICKETS_BY_ORIGIN_HEADER = [
  { label: "Origin", key: "origin" },
  { label: "Number of tickets", key: "count" },
];
export const APPOINTMENT_SERVING_TIME = [
  { label: "15", value: "15" },
  { label: "20", value: "20" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

export const profileType = [
  { label: i18n.t("USER.Branch"), value: "branch" },
  { label: i18n.t("BRANCH.Service"), value: "service" },
]


export const FILTER_SERVICE_TYPE_EVENT = [
  { value: "All", label: i18n.t("GLOBLE_MESSAGE.All") },
  { value: "queue", label: "Queue Service" },
  { value: "interactive", label: "Interactive(Limited seat)" },
  { value: "informative", label: "Informative" },
  { value: "form", label: "Form Service" },
];
export const FILTER_SERVICE_TYPE_ALL = [
  { value: "All", label: i18n.t("GLOBLE_MESSAGE.All") },
  { value: "queue", label: "Queue Service" },
  { value: "appointment", label: "Appointment Service" },
  { value: "interactive", label: "Interactive(Limited seat)" },
  { value: "informative", label: "Informative" },
  { value: "form", label: "Form Service" },
];

export const FILTER_SERVICE_TYPE_APPOINTMENT = [
  { value: "All", label: i18n.t("GLOBLE_MESSAGE.All") },
  { value: "queue", label: "Queue Service" },
  { value: "appointment", label: "Appointment Service" },
];

export const FORM_TYPE = () =>  [
  { value: "normal", label: i18n.t("FORMS.instantAppointment") },
  { value: "eservice", label: i18n.t("FORMS.e-Service") },
  { value: "donation", label: i18n.t("FORMS.donation") },
];

export const ACTION_BOOKING_TYPE = () => [
  { value: "warning", label: i18n.t("SETTING.AllowBookingButDisplayWarningMessage") },
  { value: "prevent", label: i18n.t("SETTING.PreventCustomerToBookTicket") },
]

export const ActivityTypes = {
  created: "created",
  reviewed: "reviewed",
  "document-sent": "document-sent",
  "message-sent": "message-sent",
  completed: "completed"
}

export const PeakHoursDays = [
  { value: "Monday", label: i18n.t("APPOINTMENTDASHBOARD.Monday") },
  { value: "Tuesday", label: i18n.t("APPOINTMENTDASHBOARD.Tuesday") },
  { value: "Wednesday", label: i18n.t("APPOINTMENTDASHBOARD.Wednesday") },
  { value: "Thursday", label: i18n.t("APPOINTMENTDASHBOARD.Thursday") },
  { value: "Friday", label: i18n.t("APPOINTMENTDASHBOARD.Friday") },
  { value: "Saturday", label: i18n.t("APPOINTMENTDASHBOARD.Saturday") },
  { value: "Sunday", label: i18n.t("APPOINTMENTDASHBOARD.Sunday") },
];


export const QueueFeedAction = {
  AGENT_ONLINE: "AGENT_ONLINE",
  AGENT_UPDATE: "AGENT_UPDATE",
  AGENT_LOGOUT: "AGENT_LOGOUT",
  TICKET_UPDATE: "TICKET_UPDATE",
  TICKET_COUNT: "TICKET_COUNT"
}

export const eTicketToastOption = {
  position: 'top-center',
  style: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  }
}

export const branchTerminalType = () => [
  { value: 'hall', label: i18n.t("BRANCH.Hall")},
  { value: 'window', label: i18n.t("BRANCH.Window")},
  { value: 'office', label: i18n.t("BRANCH.Office")},
  { value: 'counter', label: i18n.t("BRANCH.Counter")},
  { value: 'clinic', label: i18n.t("BRANCH.Clinic")}
]

export const donationTypeMenu = [
  {
    id: 'dashboard',
    child: [
      {
        id: 'donationDashboard'
      }
    ]
  },
  {
    id: 'services',
  },
  {
    id: 'users',
  },
  {
    id: 'settings',
  },

  {
    id: 'logs',
    child: [
      {
        id: 'merchantUsersLogs'
      },
      {
        id: 'donationLogs'
      }
    ]
  },
  {
    id: 'forms',
  },
  {

  }

]


export const donataionFormTitle = 'Donate Now!'
export const donataionFormTitleArabic = 'تبرّع الآن!'

export const donataionDes = 'Your donation, no matter how small, can make a difference to many. To donate, complete the below form, press submit and the specialized organization will contact you directly.';
export const donataionDesArbic = 'تبرّعك، مهما كان صغير، بيعمل فرق لكتير من الناس. أكمل تعبئة النموذج أدناه، من ثم اضغط على أرسل و سيتم التواصل معك من قبل المؤسسة المختصة مباشرة..';


export const userAction= () => [
  {
  value:"agent-setQueue",
  label:i18n.t("GLOBLE_MESSAGE.agentsetQueue")
},
{
  value:"agent-setCounter",
  label:i18n.t("GLOBLE_MESSAGE.agentsetCounter")
},
{
  value:"agent-login",
  label:i18n.t("GLOBLE_MESSAGE.agentlogin")
},
{
  value:"concierge-login",
  label:i18n.t("GLOBLE_MESSAGE.conciergelogin")
},
{
  value:"concierge-logout",
  label:i18n.t("GLOBLE_MESSAGE.conciergelogout")
},
{
  value:"setting-update",
  label:i18n.t("GLOBLE_MESSAGE.settingupdate")
},
{
  value:"user-create",
  label:i18n.t("GLOBLE_MESSAGE.usercreate")
},
{
  value:"agent-logout",
  label:i18n.t("GLOBLE_MESSAGE.agentlogout")
},
{
  value:"agent-closeCounter",
  label:i18n.t("GLOBLE_MESSAGE.agentcloseCounter")
},
{
  value:"branchqueue-update",
  label:i18n.t("GLOBLE_MESSAGE.branchqueueupdate")
},
{
  value:"update-password",
  label:i18n.t("GLOBLE_MESSAGE.updatepassword")
},
{
  value:"forget-password",
  label:i18n.t("GLOBLE_MESSAGE.forgetpassword")
},
{
  value:"agent-language",
  label:i18n.t("GLOBLE_MESSAGE.agentlanguage")
},
{
  value:"service-create",
  label:i18n.t("GLOBLE_MESSAGE.servicecreate")
},
{
  value:"queue-create",
  label:i18n.t("GLOBLE_MESSAGE.queuecreate")
},
{
  value:"user-update",
  label:i18n.t("GLOBLE_MESSAGE.userupdate")
},
{
  value:"agent-service",
  label:i18n.t("GLOBLE_MESSAGE.agentservice")
},
{
  value:"workprofile-update",
  label:i18n.t("GLOBLE_MESSAGE.workprofileupdate")
},
{
  value:"expose-api-token-copy",
  label:i18n.t("GLOBLE_MESSAGE.exposeapitokencopy")
},
{
  value:"login",
  label:i18n.t("LIVEFEED.Login")
},
{
  value:"logout",
  label:i18n.t("COMMON.Log Out")
},
]
export const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv','ogg'];

export const datalabelAll = 'All'
export const arbDatalabelAll = 'الكل'

export const serviceSMSType = [
  { value: "default", label: i18n.t("SERVICE.DefaultSMS") },
  { value: "customize", label: i18n.t("SERVICE.CustomizeSMS") }
]

export const queueSMSMsg = {
  en: `Your ticket {{ticketNumber}} has been booked, book your ticket before arriving from Balador {{messageUrl}}.`,
  ar: `تم حجز تذكرتك رقم {{ticketNumber}}, إحجز دورك قبل الوصول من التطبيق {{messageUrl}}`,
}

export const appointmentSMSMsg = {
  en: `Your appointment number {{ticketNumber}} for {{serviceName}} at {{merchantName}} has been confirmed from {{apptStartTime}} to {{apptEndTime}}. To manage your appointments or find the address, download the app using the following link: {{messageUrl}}`,
  ar: `تم تأكيد موعدك رقم {{ticketNumber}} لخدمة {{serviceName}} في {{merchantName}} من الساعة {{apptStartTime}} حتى الساعة {{apptEndTime}}. للتحكم بمواعيدك أو لمعرفة العنوان، حمّل تطبيق بلادور من خلال الرابط: {{messageUrl}}`,
}